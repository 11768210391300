body {
  overflow-y: auto;
  font-family: "Google Sans Text", sans-serif, "Roboto", "Helvetica", "Arial" !important;
  height: 800px;
  font-size: 1rem;
  line-height: 1.35;
  background-color: rgba(25, 118, 210, 0.5);
}

*,
:after,
:before {
  box-sizing: border-box;
}

.rootBody2 {
  margin-top: 0px !important;
  padding-top: 0px !important;

  height: 100% !important;
  background-color: transparent !important;
  margin-top: 75px;
  height: 450px !important;
  width: 600px !important;
  display: contents;
}
html,
body {
  min-width: auto !important;
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  text-align: left;
  /* background-color: rgba(25, 118, 210, 0.5); */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  /* padding-bottom: 70px; */
}

@media (min-width: 1501px) {
  .App {
    text-align: left;
    background-color: rgba(25, 118, 210, 0.5);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    min-height: 100vh;
  }
}
.App2 {
  text-align: left;
  background-color: rgba(25, 118, 210, 0.5);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  aspect-ratio: "1/1";
}
.bhold {
  height: 100% !important;
}
.flex {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
}
.flex2 {
  display: flex;
  width: 70px;
  justify-content: right;
  padding-right: 7px;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-family: "Google Sans Text", sans-serif, "Roboto", "Helvetica", "Arial" !important;
}

.App-header {
  background-color: #282c34;
  height: 45px;
  display: inline-flex;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}
.inline {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
}
.block {
  display: block;
}
.centerJustified {
  text-align: center;
}
.ulDisc {
  list-style-type: disc;
}
.ulCircle {
  list-style-type: circle;
}
.footer2 {
  text-align: center;
  font-size: 12px;
  background-color: transparent;
}
.footerTextSpacer {
  margin: 0px 10px 10px 0px;
  margin-bottom: 4px;
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.description-html {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 50px;
}
.description-html p,
ul {
  margin-top: 0px;
  margin-bottom: 0px;
}

.reg-description-html {
  margin-top: 0px;
  margin-bottom: 0px;
}
.reg-description-html p,
ul {
  margin-top: 0px;
  margin-bottom: 0px;
}
.footer-no-margin {
  margin-top: 0;
}
.socialsIcon {
  width: 32px;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 3px;
}
.center-element-horiz {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.versionNum {
  text-align: right;
  width: 100%;
  width: 100%;
  margin-right: 12px;
  position: absolute;
  bottom: 132px;
}
#top-scroll {
  margin-top: 40px;
}
.meeting-title {
  display: none;
}
.av-preview-container {
  width: 300px !important;
  height: 178px !important;
}

.width100vw {
  width: 100%;
  min-height: calc(100vh - 220px);
  /* -ms-overflow-style: none; */
}
.width100P {
  width: 100%;
  min-height: calc(100vh - 220px);
  /* -ms-overflow-style: none; */
}
.bodySpacer {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fdfdfe;
}
.max-width-container {
  max-width: 1500px;
}
.webinarDescriptionText {
  color: #454545;
  overflow: scroll;
  height: 272px;
}

.error-place-holder {
  height: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.box-shadow {
  box-shadow: 0 2px 7px rgba(178, 178, 178, 0.1);
}
#root {
  position: relative;
}
.webinarPlayerData {
  padding: 6px;
  position: relative;
  width: 250px;
  justify-content: right;
  height: 438px;
  background-color: white;
  z-index: 3;
  margin-left: calc(50vw + 177px);
  top: 20px;
  overflow: hidden;
}
#zmmtg-root {
  display: none;
  background-color: white !important;
  margin: auto auto;
  height: 100% !important;
  /* max-height: 40vh; */
  width: 100% !important;
  top: 0px !important;
  /* top: 40px !important; */
  /* margin-left: calc(50vw - 475px) !important;*/
}
#content_container {
  background: transparent !important;
  height: 100% !important;
  width: 100% !important;
}
.meeting-app {
  height: 100% !important;
  width: 100% !important;
}
.meeting-app div:first-of-type {
  height: 100%;
  width: 100%;
}
.meeting-client-inner div:first-of-type {
  height: auto;
  width: auto;
}

#wc-loading {
  height: 100% !important;
  width: 100% !important;
}
.root-inner {
  height: 100% !important;
  width: 100% !important;
}
.main-content {
  height: 100% !important;
  width: 100% !important;
}

.gallery-video-container__wrap {
  height: 100% !important;
  width: 100% !important;
}
.meeting-client-inner {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  top: 0;
  left: 0;
  margin-top: 0px;
}
.webinar-client-inner {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  top: 0;
  left: 0;
  margin-top: 0px;
}
.meeting-client {
  position: relative !important;
}
#wc-leave {
  height: 100% !important;
  width: 100% !important;
}
.sharee-container__canvas-outline {
  width: 100% !important;
  display: none;
  top: -3px;
  left: -3px;
}
.zm-modal-legacy {
  display: none;
}
#main-video {
  width: 100%;
  height: 100%;
}
.gallery-video-container__main-view {
  margin-top: 0px !important;
}
.join-dialog {
  /* height: 100% !important; */
  width: 100% !important;
}
#grid {
  box-sizing: unset;
  max-width: 1184px;
}
.embedIFrame {
  position: relative;
  top: -470px;
  left: calc(50vw - 507px);
  height: 440px;
  width: 684px;
  z-index: 4;
  overflow-y: hidden;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.embedIFrame html,
.embedIFrame body {
  overflow-y: hidden;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
}
#rootBody {
  position: relative;
  height: auto;
  min-width: auto !important;
  overflow: hidden;
}
.embedIFrame2 {
  position: relative;
  display: block;
  /* left: 0px; */
  height: 440px;
  width: 684px;
  z-index: 4;
  margin: auto auto;
}
.embedIFrame3 {
  position: relative;
  display: block;
  top: -30px;
  margin-bottom: -30px !important;
  /* left: 0px; */
  height: 448px;
  width: 100%;
  z-index: 4;
  margin: auto auto;

  /* position: relative;
  display: block;
  top: -30px;
  height: 470px;
  width: 684px;
  z-index: 4;
  margin: auto auto; */
}
.vsc-initialized {
  min-width: 100% !important;
}

.sharee-container__viewport {
  height: 398px !important;
  width: 700px !important;
}
.profile2 {
  position: relative;
  top: -415px;
  height: 555px;
  max-height: 100%;
  width: 100%;
  display: flex;
}
.playerPreviewHolder {
  top: 22px;
  margin: auto;
  height: 390px;
  /* width: 1100px; */
  display: inline-flex;
  position: relative;
}
/* .playerPreviewImg {
  height: 100%;
  margin: auto;
  position: relative;
  left: 0;
  width: 50%;
  object-fit: cover;
}
.playerPreviewImg2 {
  height: 100%;
  margin: auto;
  position: relative;
  right: 0;
  width: 50%;
  object-fit: cover;
} */
.custom-dropdown-menu.dropdown-menu {
  display: none;
}
.css-a1o2vn {
  padding-top: 42px;
}
#iframeHolder {
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
}
#widgetIframe {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-width: 100%;
  border: none;
  position: absolute;
}
#WUFrame2 {
  position: relative;
  top: 0;
  left: 0;
  border: none;
}
#WUFrameHolder {
  position: relative;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
}
#WUFrameHolder2 {
  position: relative;
  top: -11px;
  height: calc(100vh - 64px);
  width: 100%;
  display: flex;
}

.login-modal-title-text {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 18px;
}
.playerPreviewImgMain {
  margin: auto;
  margin-top: -26px;
  position: relative;
  width: 100%;
  height: 440px;
  object-fit: contain;
  box-shadow: 0px 0px 1rem #000;
  min-width: 50px;
  z-index: 2;
}
.playerPreviewImg {
  margin: auto;
  position: relative;
  width: 40px;
  height: 100%;
  object-fit: contain;
  box-shadow: 0px 0px 1rem #000;
  clip-path: inset(-50px 0px -50px -50px);

  min-width: 50px;
  object-fit: cover;
  object-position: 10% 100%;
}
.playerPreviewImg2 {
  margin: auto;
  position: relative;
  width: 55px;
  height: 100%;
  object-fit: contain;
  box-shadow: 0px 0px 1rem #000;
  clip-path: inset(-50px -50px -50px 0px);
  z-index: 1;
  min-width: 50px;
  object-fit: cover;
  object-position: 90% 100%;
}
.playerPreviewImg3 {
  margin: auto;
  margin-top: 32px;
  position: relative;
  width: 32px;
  height: 85%;
  object-fit: contain;
  box-shadow: 6px 0px 17px #888;
  object-fit: cover;
  object-position: 90% 100%;
}
.playerPreviewImg4 {
  margin: auto;
  margin-top: 30px;
  position: relative;
  width: 32px;
  height: 85%;
  object-fit: contain;
  box-shadow: -6px 0px 17px #888;
  object-fit: cover;
  object-position: 10% 100%;
}
.playerPreviewImgContainer {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
}

.container__viewport {
  margin-right: 264px !important;
}
.sharee-container__viewport {
  margin: 0px !important;
}
.sharee-container__canvas {
  width: auto;
  height: 450px;
  /* margin-left: 6px;
  margin-top: 4px; */
}
.video-share-layout {
  height: 100% !important;
  width: 100% !important;
}

.numLabelHolder {
  display: inline-flex;
  width: 25px;
  min-height: 30px;
  text-align: right;
}

.timep {
  margin-top: 10px;
  margin-bottom: 0px;
}
.numLabel {
  display: inline-flex;
  margin: auto;
  margin-right: 16px;
  margin-left: auto;
}
.subhead {
  margin-top: auto;
  margin-bottom: 4px;
  margin-left: 4px;
  font-size: 16px;
}
.headerButton {
  display: inline;
  color: white;
  /* font-size: 3.5vw; */
  font-family: "Roboto", sans-serif;
}
.logoHolder{
  position: relative;
  top: 16px;
  height: 61px;
  display: block;
}
.formerlyWTV{
  position: relative;
  margin: 0px;
  font-size: 14px;
  top: -16px;
  left: 23px;
  color: black;
}
.headerButton2 {
  margin-left: 30px;
  font-size: 25px;
  bottom: 2px;
  /* right: 0px; */
  position: relative;
  display: inline;
  color: white !important;
}
.headerButtonContainer {
  display: inline-flex;
  align-items: left;
  justify-content: left;
  width: 98%;
  margin: auto;
}
span.tv-logo {
  font: normal normal 900 30px "Roboto", sans-serif;
  font-size: inherit;
}
.homePageTitle {
  font-size: 32px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 5px;
  line-height: 49px;
}
.homePageText {
  font-size: 26px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 5px;
  line-height: 49px;
  text-align: left;
  margin: 0px 40px;
}
.homePageDesc{
  text-align: left;
  margin: 0px 40px;
}
.upsellHolder{
  display:flex;
}
#upsellGif {
  margin: auto auto;
  /* width: 62.9vw; */
  height: 400px;
  margin-top: 15px;
}
#homePageIMG2 {
  width: 46.5vw;
  height: 34vw;
  border-radius: 5px;
  margin: auto 26px;
  margin-left: 0px;
}
.homePageGrid {
  display: grid;
  max-width: 100%;
}
.heroSection {
  max-height: 100vh;
  margin-top: 32px;
  font-size: 1.125rem;
  line-height: 1.5555555556;
  font-weight: 400;
  letter-spacing: normal;
  max-width: 90vw;

}
.heroHeader {
  text-align:center;
  font-size: 52px;
  max-width: 90vw;

}
.heroDescription {
width: 80%;
max-width: 90vw;
margin:auto;
}
.heroButtonHolder{
  text-align: center;
  margin: 24px;
}
.heroIMG{
  width: 100%;
  max-width: 85vw;
}
.homePageFlex {
  max-width: 100vw;
  display: inline-flex;
  margin: auto;
  margin:12px;
}
.iconSection{
  display:flex;
  height: 220px;
  width: 800px;
  max-width: 100vw;
  font-size: 1.125rem;
  line-height: 1.5555555556;
  font-weight: 400;
  letter-spacing: normal;
}
.iconSectionSpacer{
  width: 800px;
  margin: auto;
  height: 500px;  
  max-width: 100vw;

}
.iconSectionTitle{
  text-align: center;
  font-size: 28px;
  margin-top: 38px;
  margin-bottom: 40px;
}
.iconItem{
  width:300px;
  display:block;
  margin: auto;
  max-width: 45vw;
}
.iconSectionIcon{
  margin-left: calc(50% - 36px);
  font-size: 45px;
}
.bulletSection{
  max-width: 1000px;
  margin: 24px;
  margin-top: 48px;
}
.homePageButton1 {
  margin-left: 0px;
  font-size: 1rem !important;
  text-transform: none !important;
}
.turtleBurger {
  display: none;
  margin-left: 12px;
  margin-right: auto;
}
.turtleNav {
  display: contents;
}
.turtleNav2 {
  right: -30px;
  display: flex;
  position: relative;
}
.usernameDisplay {
  /* margin-left:15px;
  margin-right: 15px; */
  background: #fff;
  border-bottom: 1px solid #b9c3cc;
}
.username-display-p {
  width: 100%;
  max-width: 200px;
  margin: 0;
  padding: 8px 5px 5px 5px;
  color: #1976d2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 999px) {
  .pricingSelect {
    scale: 85%;
  }
  .homePageFlex {
    display: inline-flex;
    /* width: 100%; */
  }
  #upsellGif {
    border-radius: 5px;
    /* margin-right: -20px; */
  }
  #homePageIMG2 {
    width: 63vw;
    height: 45.5vw;
    border-radius: 5px;
    margin: auto auto;
    margin-left: auto;
    margin-top: 26px;
  }
  .turtleBurger {
    display: inline-flex;
  }
  .turtleNav {
    display: none;
  }
  .turtleNav2 {
    right: 0;
    width: 100%;
    margin-right: 16px;
    justify-content: right;
    margin-left: auto;
    display: flex;
  }
}
@media (max-width: 500px) {
  .turtleNav2 {
    display: none;
  }
}
.menuSpacer {
  height: 65px;
}
.navButtons {
  display: contents;
}
.navModalButton {
  display: block !important;
}
.navButtons {
  display: none !important;
}
.testimonial {
  margin: 0px;
  font-size: 22px;
  text-align: center;
  max-width: 100%;
}

.navButtons2 {
  display: block;
  background: white;
  border-radius: 4px;
  margin-top: 66px;
}
.navLinks {
  color: black;
}
.navPath {
  display: flex;
  margin-left: 65px;
  width: 348px;
}
.pathItem {
  margin: 3px;
  margin-top: 5px;
  margin-bottom: 4px;
  white-space: nowrap;
}
.CategoryHolder {
  width: 400px;
  justify-content: left;
  text-align: left;
  margin: auto;
}
.textHolder {
  margin: auto;

  min-width: 140px;
}
.textHolder2 {
  min-width: 60px;
  margin-right: 5px;
  display: inline-flex;
}
.timeFrameSelector {
  display: flex;
  margin-left: auto;
  margin-top: -2px;
  margin-bottom: -56px;
  width: 200px;
}
.timeFrameItem {
  margin: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.timeFrameItemActive {
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 2px;
  margin-top: 15px;
  margin-bottom: 5px;
  background-color: lightgrey;
  border-radius: 100px;
}
.webinarTable {
  max-width: 100%;
  margin: auto;
  min-height: 50px;
}
.webinarText {
  white-space: pre-line;
}
.webinarBanner {
  height: 350px;
  max-width: 90%;
}
.carouTitle {
  text-decoration: none !important;
  padding-left: 2px;
  margin: 0px;

  color: black;
}
.carouHost {
  text-decoration: none !important;
  padding-left: 2px;
  margin: 0px;

  color: grey;
}
.carouImage a {
  color: #ffffff;
  text-decoration: none;
}
.carouImage {
  border: 1px solid grey;
}
.webinarLogo {
  max-width: 21%;
  margin: 10px;
  margin-left: -7px;
}
.App-link {
  color: #61dafb;
}
.wc-loading {
  height: 100%;
}
.join-meeting {
  height: 10% !important;
  max-height: 10% !important;
}

.loading-main {
  height: 100%;
}
.loading-img {
  margin-top: 25%;
}
.loading {
  margin: auto auto;
  z-index: 7;
  height: 0px;
  width: 530px;
}
.loadingPositioner {
  padding: 16px;
  width: 45px;
  margin: auto;
}
.logoImage {
  margin: auto;
  margin-top: 4px;

  max-height: 75px;
  width: 75px;
}
.player2Spacer {
  margin-top: 12px;
  height: 0px;
}
.player3Spacer {
  top: 0px;
}
html {
  /* min-width: 100% !important; */
  height: 100%;
  overflow: auto !important;
}
.audio-option-menu__pop-menu {
  bottom: 116px !important;
  left: 143px !important;
  position: relative;
}
.last-chat-message-tip__container {
  bottom: 104px !important;
  position: relative;
}

.hitp {
  margin-bottom: 6px;
  margin-top: 2px;
  color: black;
}

.hitelement {
  margin-bottom: 20px;
}
.css-k76fqu {
  max-height: 56px;
}

/* .tierText {
  margin-top: 1px;
}
.tierSpacer {
  margin-left: 20px;
}
.priceText {
  margin-top: 1px;
} */

#outlined-basic2 {
  padding: 1px;
}

.justifyLeft {
  text-align: left;
  margin-left: 50px;
  margin-right: 50px;
}
.timeText {
  font-size: 14px;
  margin: 0px;
  color: #515151;
}
.closePopup {
  margin-left: 95.5%;
  margin-bottom: -14px;
}
#confirmPop {
  background-color: #000000b8;
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0px;
  width: auto;
  z-index: 2;
  margin-left: auto;
  pointer-events: none;
}
.confirmSpacer {
  position: fixed;
  left: calc(50% - 270px);
  top: calc(50% - 140px);
  /* margin: auto; */
  z-index: 100;
}
.confirmHolder {
  position: fixed;
  /* left: 0; */
  /* right: 0; */
  /* width: 100%; */
  top: 0px;
  bottom: 0px;
  /* width: auto; */
  margin-left: auto;
}
.confirmHolder2 {
  margin-top: 150px;
  z-index: 10;
}

#submitConfirm {
  padding: 16px;
  margin: auto auto;
  width: 650px;
  max-width: 100%;
  z-index: 7;
  position: relative;
  height: auto;
}
#submitConfirmHolder {
  position: relative;

  height: 0px;
  z-index: 2;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#searchbox {
  margin-left: 10px;
  margin-right: 10px;
}

ul {
  list-style-type: disc;
}
.css-1yo9slo-MuiToolbar-root {
  min-height: 64px !important;
}

#webinars-box-container {
  display: flex;
  flex-wrap: wrap;
  min-width: 30%;
  list-style: none;
  margin: 0px -0.5rem;
}

/* .MuiDrawer-paper {
  padding-left: 20px;
} */

.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 15px;
  background: none;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  border: none;
}

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.not-found-title {
  font-size: 32px;
}

.not-found-message {
  max-width: 600px;
}

.not-found-message p {
  font-size: 20px;
  font-weight: 300;
}

.not-found-actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.terms-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.terms-title {
  width: 100%;
  font-weight: 600;
  margin: 0;
}

.terms-section {
  width: 100%;
  white-space: pre-wrap;
  margin: 5px;
}

.description-scroll::-webkit-scrollbar {
  max-height: 300px;
}

.tick {
  display: flex;
  flex-direction: row;
  font-size: calc(16px + 0.2vw);
  flex: 1 4 auto;
  min-width: 0;
  max-width: 100%;
  align-items: flex-end;
}

.tick-flip {
  margin-left: 0.03em !important;
  margin-right: 0.03em !important;
  flex: 1 4 auto;
}

.tick-icon {
  flex-shrink: 4;
}

.tick-label {
  margin: auto;
  padding: 0 18px;
  flex-shrink: 4;
  min-width: 0;
  font-size: 16px;
  border-bottom: 1px solid white;
}

.tick-items {
  display: flex;
  flex-wrap: nowrap;
}

.tick-credits {
  display: none !important;
}

.status-response-open {
  margin: 0 0 0 5px;
  color: green;
  white-space: nowrap;
  overflow: visible;
}

.status-response-taken {
  display: inline-block;
  text-wrap: nowrap;
  margin: 0 0 0 5px;
  color: red;
}

.host-webinar-collection {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 4vw;
  padding-top: 0;
}

.collection-title {
  margin: 10px 0px 20px 0px;
}

.express-url-container {
  font-size: 16px;
  margin-top: 2px;
  background: #e5e5e9;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 10px;
  white-space: nowrap;
  align-items: flex-start;
  max-width: fit-content;
}
.imgPreview {
  max-height: 48px;
  /* width: 90px; */
  margin-left: 10px;
  margin-top: 4px;
}
.spiderStatus{
  width: 24px;
}
/* #card-container {
  min-width: 50%;
  max-width: 50%;
} */
/* #ind-webinar-container {
  flex: 1 0 auto;
  -webkit-box-flex: 1;
  width: 30rem;
  max-width: 50%;
  padding: 0px 0.5rem;
}
#ind-webinar-desc {
  display: inline-flex;
} */
.libraryImg {
  width: 100%;
  height: 180px;
  /* max-height: 292px; */
  overflow: hidden;
  object-fit: contain;
  object-position: center;
}
.libraryImg2 {
  width: 100%;
  height: 180px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
}
.libraryLower {
  position: absolute;
  margin-top: auto;
  margin-bottom: 12px;
  bottom: 0px;
  width: 276px;
}

#wrapper {
  position: relative;
  max-width: 1000px;
  min-height: 100%;
  background-color: #fdfdfe;
  width: 100%;
}

@media (max-width: 999px) {
  #wrapper {
    padding-top: 84px;
    position: relative;
    max-width: 1000px;
    min-height: 100%;
    padding: 0 0 100px;
    background-color: #fdfdfe;
    width: 100%;
  }
}

.page-container {
  padding-bottom: 40px;
  min-height: 640px;
}

@media (max-width: 999px) {
  .page-container {
    min-height: inherit;
  }
}

.manage-event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 150px;
  margin-left: 5px;
  margin-right: 5px;
}

.events-outer-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  background-color: #fdfdfe;
  width: 100%;
  max-width: 1000px;
  margin-left: 5px;
}

.events-container {
  padding: 0px 10px;
}

.confirmation-background-color {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}

.confirmation-font-color {
  display: flex;
  flex-direction: column;
}

.events-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px;
}

.events-border {
  border-bottom: 1px solid #eff3f6;
  margin-right: 20px;
}

.events-header-section {
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
  margin-left: 10px;
}

.meeting-webinar-container {
  display: flex;
}

.align-left {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}

.events-header-title {
  font-weight: 700;
  font-size: 20px;
  margin-left: 10px;
}
/* .events-header-title.--activated {
  color: "#1976d2";
  border-bottom: "4px solid #1976d2";
} */

.events-tabs-wrapper {
  width: 100%;
  border-bottom: 1px solid #eff3f6;
  margin: 20px 0px 40px 0px !important;
}

.events-header-tabs {
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  height: 40px;
  padding: 4px 10px 0px 10px;
  padding-top: 4px;
}

.events-header-filter-container {
  height: 100%;
  display: flex;
  border: 2px solid transparent;
  padding: 0 13px;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: all;
  text-transform: capitalize;
  background: transparent;
  position: relative;
}

.event-header-filter-title {
  color: #42454b;
  font-size: 15px;
  font-weight: 500;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: all;
  text-transform: capitalize;
}

.event-header-filter-title:hover {
  color: #1976d2;
  cursor: pointer;
}

.--activated {
  border-bottom: 2px solid #1976d2;
  background: #fff;
}
.--activatedTab {
  border-bottom: 2px solid #1976d2;
  background: #fff;
  color: #1976d2;
}

.event-header-filter-title.--activated {
  color: #1976d2;
  border: none;
}

.events-header-button-container {
  display: flex;
  align-items: center;
}

.--activated.all {
  color: #1976d2;
}
.--activated.standard {
  color: #1976d2;
}
.--activated.series {
  color: #1976d2;
}
.--activated.automated {
  color: #1976d2;
}
.--activated.archived {
  color: #1976d2;
}

.--activated:hover {
  color: #1976d2;
}

.ripple-button {
  border-radius: 4px;
  border: none;
  margin: 0px 0px;
  padding: 10px 15px;
  background: #1976d2;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: background-color 0.3s, box-shadow 0.3s;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #145a8d;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.5);
  }

  &:active {
    background-color: #0f4c81;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}
.rankingTooltip{
  padding: 0px 7px;
  width: 25px;
  border: 1px solid #1976d2;
  border-radius: 16px;
  position: relative;
  top: -12px;
  left: -14px;
  background: white;
}
.catalogTitle{
  white-space: nowrap;
  overflow: hidden;
}
.ripple-button2 {
  border-radius: 4px;
  border: none;
  margin: 10px 0px;
  padding: 10px 15px;
  background: #1976d2;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: background-color 0.3s, box-shadow 0.3s;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #145a8d;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.5);
  }

  &:active {
    background-color: #0f4c81;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.ripple-button.ripple-login {
  width: 150px;
}

/* .ripple-button > .ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  background: #63a4ff;
  display: block;
  content: '';
  border-radius: 9999px;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}

.ripple-button > .content {
  position: relative;
  z-index: 2;
} */

.sort-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sort-events-search-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #929292;
  text-align: right;
  align-items: center;
}

.sort-by-title {
  font-size: 14px;
  color: black;
}

.events-sort-by {
  min-width: auto;
  font-weight: 600;
}

.sort-drop-down-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.chevron-sort-by {
  display: flex;
  flex-direction: row;
}

.drop-down-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-webinars-container {
  position: relative;
}

.search-container {
  position: relative;
  display: inline-block;
}

.search-container input {
  height: 40px; /* Adjust as needed */
  padding-right: 40px; /* Make room for the search icon */
}

.search-input {
  outline: none;
  height: 40px;
  padding-right: 40px;
  padding-left: 20px;
  box-sizing: border-box;
  border: 1px solid #e8ecf3;
  color: #929292;
  border-radius: 5px;
}

.search-input::placeholder {
  color: #b9c3cc;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.45);
}
.search-icon2 {
  margin-top: 0px;
  top: -4px;
  position: relative;
  height: 24px;
  margin-bottom: -10px;
}

.planHolder {
  border: solid grey 3px;
  border-radius: 12px;
  width: 490px;
  max-width: 100%;
  padding: 26px;
  padding-top: 0px;
  padding-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
}
.pricingHeader {
  text-align: center;
}
.pricingSelect {
  display: block;
  margin: auto;
  margin-bottom: 8px;
  max-width: 600px;
}
.priceText {
  margin-bottom: -6px;
  margin-bottom: 2px;
  margin-top: 12px;
  text-align: center;
}

.tierBoxTitle {
  margin-bottom: 2px;
  text-align: center;
}
.lightGreyBG {
  background: lightgrey;
  height: 52px;
}
.whiteBG {
  height: 52px;
}
.pricingBgSpacer {
  position: relative;
  width: calc(100% + 32px);
  left: -16px;
  height: 200px;
  margin-bottom: -200px;
  z-index: 0;
  top: 231px;
}
.planFieldLabels {
  padding-top: 246px;
  margin-bottom: 29px;
  z-index: 1;
}
.pricingStat {
  height: 31px;
  margin-bottom: 20px;
  text-align: center;
}
.pricingStatTitle {
  height: 31px;
  margin-bottom: 20px;
}
.pricingCheck {
  height: 31px;
  position: relative;
  top: 0px;
  margin-right: 8px;
}
.EssentialAppsHolder{
  width:100%;
  height:100vw;
  max-width: 150vh;
}
.EssentialAppsIframe{
  width:100%;
  height:100%
}
.attcapLabel {
  margin: auto 12px auto auto;
}
.attcapHolder {
  margin: auto;
  display: inline-flex;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 32px;
}
.attcapOption {
  padding: 12px;
  border-radius: 32px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  background-color: white;
  color: black;
}
.attcapOption.selected {
  background-color: #1976d2;
  color: white;
}
.attcapOption:not(.selected):hover {
  background-color: #1976d2;
  color: white;
}
.attcapOption:hover {
  background-color: #1976d2 !important;
  color: white !important;
}
.webinars-search-total {
  color: #6c737a;
  margin-left: 40px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  margin-right: 5px;
}

.webinar-block-container.box-shadow {
  box-shadow: 0 2px 7px rgba(178, 178, 178, 0.1);
}

.webinar-block-container {
  display: flex;
  margin-bottom: 10px;
  border: 1px solid #eff3f6;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #fff;
}

.webinar-block-content.event {
  padding: 0;
}

.webinar-block-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px;
  width: 100%;
  position: relative;
}

.webinar-block-left {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}

.webinar-block-left.event {
  padding: 20px 0 20px 20px;
  cursor: pointer;
  border-right: 1px solid #eff3f6;
}

.webinar-block-right {
  display: flex;
  align-items: center;
}

.webinar-block-right.event {
  display: flex;
  align-items: center;
  margin: 20px;
  background-color: #1976d2;
  border-radius: 5px;
}

.webinar-block-right.event.no-background {
  display: flex;
  align-items: center;
  margin: 20px;
  background-color: transparent;
  border-radius: 5px;
}

.webinar-info {
  display: flex;
  flex-direction: column;
  max-width: 90%;
}

.webinar-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  flex-wrap: wrap;
}

.webinar-block-title {
  text-overflow: unset;
  white-space: normal;
  overflow: auto;
  max-width: 100%;
}

.webinar-info-topic {
  width: 33%;
}
.webinar-info-date {
  width: 36%;
}

.webinar-info-date {
  margin-bottom: 0px;
}
.category-subcategory {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 30%;
  align-self: flex-end;
}
.meeting-date-time-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-self: flex-end;
}
@media (max-width: 650px) {
  /* .ripple-button {
    width: 90%;
  } */
  .webinar-info-topic,
  .webinar-info-date {
    width: 100%;
  }
  .category-subcategory {
    width: 100%;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .webinar-block-left {
    flex-direction: column;
  }
  .attcapLabel {
    margin-right: auto;
    margin-left: calc(50% - 214px);
}
}

.webinar-block-title-sub {
  color: #5d676b;
}

.webinar-block-title-cat {
  color: #2c3336;
}

.webinar-block-stats {
  color: #929292;
  font-size: 13px;
}

.webinar-options {
  position: relative;
}

.menu-item-event-options {
  color: #6c737a !important;
}

.webinar-options-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  height: 34px;
  width: 34px;
  padding: 0;
  border-radius: 5px;
}

.event-block-wrapper:hover {
  box-shadow: 0 10px 15px -10px rgba(30, 45, 62, 0.21),
    0 5px 40px -10px rgba(31, 44, 60, 0.1);
  transition: 0.3s;
}

.Box:hover {
  box-shadow: 0 10px 15px -10px rgba(30, 45, 62, 0.21),
    0 5px 40px -10px rgba(31, 44, 60, 0.1);
  transition: 0.3s;
}

.event-links-breadcrumb-container {
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.event-links-breadcrumb-container span {
  color: #42454b;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-breadcrumb {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.event-links-breadcrumb-container span {
  color: #42454b;
}

.event-breadcrumb-link:hover {
  color: #1976d2;
  border-bottom: 1px solid #1976d2;
  cursor: pointer;
}

.event-manage-header-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.event-manage-area {
  display: flex;
  align-items: flex-end;
  flex-direction: column !important;
  justify-content: flex-end;
  margin-left: 20px;
}

.event-manage-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 1px;
  color: #2c3336;
  padding-right: 5px;
}

.event-manage-options {
  position: relative;
  margin-right: 8px;
}

.event-options {
  width: 34px;
  height: 34px;
  border: 1px solid #9fa6ad;
  border-radius: 5px;
}

.edit-topic-icon {
  cursor: pointer;
  display: flex;
  align-self: flex-start;
}

.session-header-tabs {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  border: 1px solid #eff3f6;
  margin-bottom: 10px;
  border-radius: 1px;
}

.session-tab-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-size: 16px;
  color: #42454b;
  flex: 1;
  height: 100%;
  background-color: transparent;
  border: 0;
  border-right: 1px solid #eff3f6;
  cursor: pointer;
}

.session-tab-buttons:hover {
  color: #1976d2;
  background: #fff;
}

.session-tab-buttons.--activated {
  color: #1976d2;
  border-bottom: 3px solid #1976d2;
  background: #fff;
}

.customize-event-container {
  border-radius: 5px;
  background-color: #fff;
}

.summary-container {
  border: 1px solid #e8ecf3;
  border-radius: 5px;
  background-color: #fff;
}

.summary-content {
  display: flex;
  flex-direction: column;
}

.summary-filter-container {
  border-bottom: 1px solid #e8ecf3;
}

.summary-filter-header {
  display: flex;
  height: 80px;
}

.summary-filter-buttons {
  padding-left: 15px;
  display: flex;
  height: 72px;
  padding-top: 20px;
}

.summary-filter-button {
  height: 100%;
  color: #2c3336;
  display: flex;
  border: 2px solid transparent;
  padding: 0 13px;
  transition: 0.3s;
  user-select: none;
  background: transparent;
}

.summary-filter-button:hover {
  color: #929292;
}

.summary-filter-button.active {
  border-bottom: 2px solid #2c3336;
}
.summary-filter-button.active:hover {
  color: #2c3336;
}

.summary-schedule {
  cursor: auto;
  display: flex;
  align-items: center;
  margin: 0 16px 0 auto;
}

.summary-schedule-button {
  border: 1px solid #dee4e8;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #1976d2;
  background: #fff;
  padding: 9px 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.summary-schedule-button:hover {
  border-color: #1976d2;
}

.drop-down-webinar-options {
  cursor: "pointer";
}

.drop-down-webinar-options.manage {
  border: 1px solid #dee4e8;
  border-radius: 5px;
  margin-bottom: 2px;
}

.p-container {
  padding-bottom: 5px;
}

#event-manage {
  margin-bottom: 10px;
}

.button-flex {
  display: flex;
  flex-flow: row wrap;
}

.button-flex-space-between {
  justify-content: space-between;
  margin-bottom: 60px;
}

.button-event-types {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: -50px;
  border: 1px solid #dee4e8;
  border-radius: 4px;
  color: #2c3336;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 14px;
  border-color: #dee4e8;
  padding-left: 10px;
  padding-right: 10px;
}

.button-event-types:hover {
  background-color: #fff;
}

.button-content-span {
  margin-left: 5px;
  font-size: 16px;
}

.event-title {
  margin-bottom: 50px;
  text-align: center;
  font-weight: 300;
}

.Title {
  color: #000;
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
}

.second-step {
  margin-bottom: 32px;
}

.Box-wrapper {
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.Box {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eff3f6;
  cursor: pointer;
  height: 96px;
  text-align: left;
  transition: all 0.3s ease;
}

.Box.--no-hover {
  cursor: default;
}

.Box.--open {
  border-bottom: 1px solid #eff3f6;
  border-radius: 4px 4px 0 0;
  border-left-style: hidden;
  border-radius: 4px 4px 0 0;
  border-right-style: hidden;

  border-top: 4px solid #1976d2;
  cursor: pointer;
}

.Box-header {
  align-items: center;
  border-width: 0 1px;
  display: flex;
  padding: 25px;
}

.Box-info {
  margin-left: 10px;
}

.Box-title {
  color: #2c3336;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding-right: 20px;
}

.Box-border {
  background: #fff;
  border-radius: 0 0 4px 4px;
  margin-left: 5px;
  margin-right: 5px;
}

.Box-border-button {
  display: flex;
  flex-direction: column;
}

.Box-border.border {
  background: #fff;
  border-radius: 0 0 4px 4px;
  /* border-right: 1px solid #eff3f6;
  border-left: 1px solid #eff3f6; */
  border-bottom: 1px solid #eff3f6;
  border-radius: 0 0 4px 4px;
}

.Box-content {
  background: #fff;
  border-radius: 0 0 4px 4px;
  margin: auto;
  padding: 40px 40px 10px 40px;
  width: 80%;
}

.Box-content-advanced {
  background: #fff;
  border-radius: 0 0 4px 4px;
  width: 100%;
}
.Box-content-advanced.--hide {
  display: none;
}

.Box-content.--hide {
  display: none;
}

.summary-schedule.--hide {
  display: none;
}

.form-row {
  zoom: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: 0;
  margin-right: 0;
  position: relative;
}

.form-item {
  font-feature-settings: "tnum";
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  margin: 0 0 15px;
  padding: 0;
  vertical-align: top;
  font-size: 16px;
}

.form-row.form-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.event-host-label {
  margin: 0;
  padding: 0;
  vertical-align: top;
}

/* .event-host-label {
  padding: 10px 0;
} */

.create-name-bottom {
  flex-direction: column;
  padding-bottom: 40px;
  justify-content: center;
  display: flex;
  /* flex-flow: row wrap; */
}

.continue-button-center {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.continue-button {
  cursor: pointer;
  font-size: 16px;
  background: #1976d2;
  color: #fff;
  border: 1px solid #1976d2;
  padding: 5px 10px;
  border-radius: 5px;
  align-self: center;
}

.customize-list-icon {
  color: #1976d2;
  margin-right: 25px;
}

.Box-hint {
  color: #6c737a;
  font-size: 14px;
  padding-top: 5px;
}

.date-time-picker-container {
  display: flex;
  width: 100%;

  justify-content: center;
  align-content: center;
}
.time-picker-container {
  width: 240px;
  margin-right: 20px;
}

.time-picker-container.start-time {
  display: flex;
}

.time-picker-container.meeting-length {
  margin-top: 20px;
}

.date-time-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 40px 200px;
  /* background: #fafafa; */
  border-radius: 5px;
  height: 400px;
  overflow: hidden;
}

.date-time-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@media (max-width: 920px) {
  .date-time-container {
    display: flex;
    flex-direction: column;
  }
}
.date-picker-container {
  width: 240px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.date-time-button {
  color: #fff;
  background: #1976d2;
  border-radius: 5px;
  align-items: center;
  border-width: 1px;
  box-shadow: none;
  display: inline-flex;
  font-size: 13px;
  font-weight: 700;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 37px;
  padding: 0 16px;
  text-shadow: none;
}

.date-time-button:hover {
  background: rgba(25, 118, 210, 0.85);
}

.date-time-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #2c3336;
  text-align: center;
  margin-bottom: 20px;
}

.date-time-title,
.date-time-button,
.date-picker-container,
.time-picker-container {
  min-width: 215px;
}

.start-end-container {
  display: flex;
  flex-direction: column;
}

.bottom-radius {
  border-radius: 0 0 6px 6px;
}

.customize-child-container {
  zoom: 1;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.tabs-container {
  display: flex;
  flex-direction: column;
}

.resources-container {
  width: 100%;
}

.advanced-button-container {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px dashed #e8ecf3;
  width: 100%;
  color: #2c3336;
}

.advanced-info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #2c3336;
}

.advanced-title {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
  width: 100%;
  color: #2c3336;
}

.advanced-subtitle {
  font-size: 12px;
  color: #6c737a;
  inline-size: 90%;
  width: 100%;
}

.pagination-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
}

.resource-form-container {
  width: 100%;
  padding: 0 10px 0 10px;
}

.options-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 5px;
}

.form-input-field {
  margin-bottom: 24px;
}

.options-container {
  display: flex;
  flex-direction: column;
}

.question-field {
  display: flex;
  flex-direction: column;
}

.close-modal-button {
  padding-bottom: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  margin: 10px;
  font-size: 24px;
  line-height: 40px;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: rgba(131, 139, 151, 0.7);
  color: white;
  cursor: pointer;
  outline: none;
}

.form-title {
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  color: #353d41;
  cursor: default;
}

.form-title-container {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e8ecf3;
}

.webinar-description-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 24px;
}

.host-name-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 12vw;
  font-size: 1.6vw;
  background: #353d41;
  border-radius: 5px;
  color: white;
}

.form-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.admin-form-button-container {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.form-field-container {
  margin: 20px 0;
  min-width: 215px;
  margin-right: 5px;
}

.registration-box-container {
  display: flex;
  position: relative;
  padding: 50px;
  align-items: center;
  justify-content: center;
}

.registration-box {
  flex: 1;
  max-width: 220px;
  position: relative;
}

.registration-box-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #e8ecf3;
  /* border-bottom: none; */
  /* border-bottom: none; */
  padding: 30px;
  /* height: 150px; */
  border-radius: 5px;
  /* height: 100%; */
  /* margin-bottom: 20px; */
}

.registration-title {
  color: #42454b;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.banner-container {
  text-align: center;
}

@media (max-width: 767px) {
  .banner-container {
    text-align: center;
    height: 100px;
  }
}

.banner-error-msg {
  color: red;
}

.banner-image-inline-form {
  position: relative;
  width: 100%;
  object-fit: scale-down;
  background: transparent;
}

.registration-banner-image {
  max-height: 200px;
  max-width: 640px;
  border-radius: 8px;
}

.tab-panel-registration {
  min-width: 770px;
}

.ripple-button.registration {
  /* border-radius: 4px; */
  border: none;
  margin: 0;
  padding: 9px 12px;
  background: #1976d2;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  height: 45px;
}

.registration-page-editor {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
}

.editor-column-page {
  width: 325px;
  background-color: #fff;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;
}
.registration-form-outer {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 50px 40px 32px 40px;
  margin: 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  border: 1px solid #ededf4;
  justify-content: center;
  width: 93%;
}

.registration-demo-page {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-height: 430px;
  position: relative;
  word-break: break-word;
  margin-left: 40px;
  width: 100%;
}

.registration-page-topic {
  font-size: 30px;
  color: black;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: 500;
}

.registration-button-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 40px; */
}

.registration-inner-container {
  display: flex;
  flex-direction: row;
}

.registration-image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  align-self: center;
}

.profile-image-preview {
  display: flex;
  justify-content: flex-start;
  background: #fff;
}

.date-time-registration {
  color: black;
  width: 86px;
  white-space: nowrap;
}

.editor-title {
  padding: 25px;
  font-weight: 500;
  border-bottom: 1px solid #e2e2e2;
}

.logo-registration-page {
  width: 1vw;
  height: 1vw;
  margin-left: 5px;
  border-radius: 50%;
}

.registration-save-button {
  width: calc(100% - 40px);
  border: 0;
  font-size: 13px;
  font-weight: 600;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  margin: 20px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.registration-save-button:hover {
  width: calc(100% - 40px);
  border: 0;
  font-size: 13px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 5px;
  margin: 20px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.register-form-menu {
  display: flex;
  flex-direction: column;
  width: 106%;
  max-height: 500px;
  overflow-y: scroll;
}
.register-form-menu::-webkit-scrollbar {
  display: none;
}

.tags-input-field {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.confirmation-page-demo {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  background: #fff;
  padding: 100px 50px 100px 50px;
  border: 1px solid #fff;
  border-radius: 5px;
  align-items: center;
  height: 100%;
}

.confirmation-page-demo-form {
  border-radius: 5px;
  padding: 10px 20px;
  width: 80%;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4);
}

.demo-page-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 35px;
  color: black;
  margin-bottom: 100px;
  margin-top: 20px;
  font-weight: 500;
  padding-bottom: 10px;
}

.additional-msg-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 40px;
}

.additional-msg {
  display: flex;
  color: black;
  padding: 20px 40px 20px 40px;
  font-size: 20px;
}

.confirmation-demo-label-col {
  width: 28%;
  color: #282c34;
}

/* .register-form-container {
  width: 95%;
  padding-right: 20px;
  padding-left: 20px;
  max-height: 500px;
  overflow-y: auto;
} */
.thing {
  height: 400px;
  justify-content: center;
  align-content: center;
}

.slimInput {
  padding: 1px !important;
}
.slimInput div {
  padding: 0px !important;
}
.slimInput div input {
  padding: 7px !important;
}

.navLink {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  top: 6px;
  padding-top: 4px;
  padding-bottom: 16px;
  margin: 0px;
  padding-left: 12px;
}

.resource-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: height 5s ease-out;
}

.resource-item-container {
  padding: 10px;
  margin: 0 10px;
  outline: none;
}

.resource-item {
  display: flex;
  padding: 10px;
  margin: 0 10px;
  border: 1px solid #e8ecf3;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

.resource-icon-info-container {
  display: flex;
  padding-right: 10px;
}

.resource-icon {
  margin-left: 10px;
  margin-right: 10px;
}

.resource-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.options-list-container {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
}

.edit-resource {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 25px;
}

.delete-resource {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  padding-right: 5px;
}

.resource-title-info {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.resource-type-info {
  display: inline-flex;
  text-wrap: nowrap;
  font-size: 12px;
  color: rgba(131, 139, 151, 0.7);
  inline-size: 90%;
}

.edit-resource-button {
  color: #3b98df;
  background-color: transparent;
  border: none;
}

.update-form-title {
  display: flex;
  padding: 10px 0px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.update-title {
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  color: #353d41;
}

.resource-form-open {
  background: rgba(25, 118, 210, 0.85);
  color: white;
}

.empty-resources {
  text-align: center;
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  font-size: 14.5px;
  padding: 55px 0;
}

.empty-resources-info {
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 150px;
  font-size: 15px;
}

.empty-resources-disclaimer {
  margin: auto 0 auto 15px;
  color: #6c737a;
}

.category-subcategory-container {
  display: flex;
  align-items: center;
}

.subcategory-option-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.input-info-wrapper {
  display: flex;
  justify-content: flex-start;
}

.pre-post-container {
  padding: 20px 40px 40px 40px;
}

.pre-settings-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #eff3f6;
  margin-bottom: 18px;
  padding-bottom: 20px;
}

.pre-post-row {
  zoom: 1;
  box-sizing: border-box;
  display: block;
  height: auto;
  margin-left: 0;
  margin-right: 0;
  position: relative;
}

.pre-post-label {
  margin: 0 0 5px 0;
  display: inline-block;
  text-wrap: nowrap;
}

.invisible-submit-button {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  z-index: -9999px;
}

.description-form-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.logo-placeholder-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.claimModalContainer{
  max-width: 510px;
  margin:0px;
  padding:0px;
}
.first-webinar-walkthrough {
  display: flex;
  min-width: 100%;
  flex-direction: column;
  align-content: space-between;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  padding-left: 20px;
  font-size: 20px;
  margin: 0px 4px !important;
  padding: 0px 4px 0px 4px;
}

.next-step-container {
  display: flex;
  width: 97%;
  justify-content: flex-start;
  padding: 0 10px 10px 0;
  margin: 20px;
  margin-bottom: 0;
  margin-left: 10px;
  border-bottom: 1px solid #e8ecf3;
}

.next-step-title {
  margin-bottom: 0;
  margin-top: 0;
}

.next-step-content {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.next-step-link {
  display: flex;
  align-items: center;
}

.next-step-link-text {
  color: #1976d2;
  cursor: pointer;
  margin: 0;
}

.next-step-info {
  font-size: 14px;
  margin: 0;
  line-height: 22px;
  padding-bottom: 4px;
}

.snack-icon-message {
  display: flex;
  align-items: flex-end;
  text-align: left;
}

.google-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-google-btn {
  width: 100%;
  height: 49px;
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid black;
  font-size: 16px;
  color: black;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-content-container {
  display: flex;
  padding: 0px 0px;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.delete-modal-title-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #e8ecf3;
}

.delete-modal-info-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.popup-modal-info-container {
  display: flex;
  flex-direction: column;
}

.delete-modal-info {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.delete-items-list {
  list-style-type: disc;
}

.delete-webinar-btns {
  display: flex;
  justify-content: flex-end;
}

.header-popup-close {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.new-user-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 10px 15px -10px rgba(30, 45, 62, 0.91),
    0 5px 40px -10px rgba(31, 44, 60, 0.91);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  max-width: 400px;
  /* padding: 10px 15px 10px 15px; */
}

.new-user-popup-msg {
  padding: 0px 15px 20px 20px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.pre-party-question-container {
  width: 100%;
  margin-left: 20px;
}

.pre-post-btn-container {
  display: flex;
  justify-content: center;
}

.pre-meet-info-container {
  display: flex;
  flex-direction: column;
}

.pre-meet-info {
  align-self: center;
  padding: 0 0 20px 0;
}

.email-reminder-item {
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin: 0 32px;
  border-top: 1px dashed #e8ecf3;
}

.email-reminder-type {
  flex: 1;
}

.admin-form-title {
  display: flex;
  justify-content: center;
  margin: 10px;
  color: #2c3336;
}

.admin-form-container {
  width: 100%;
  padding-left: 20px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.browseMore {
  justify-content: center;
  display: flex;
  width: 100%;
}

.content-wrap-register {
  padding: 0;
  margin: 0;
}

.register-form {
  margin: 10px auto 24px;
  width: 720px;
  padding: 0 40px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #ededf4;
}

.register-form-info {
  margin-top: 0;
}

.banner-image-container {
  text-align: center;
  padding-top: 20px;
}

.register-banner-image {
  max-height: 200px;
  max-width: 100%;
  border-radius: 8px;
}

.title-detail {
  margin-top: 0px;
}

.on-demand-topic {
  display: block;
  max-width: 100%;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 16px;
}

.register-complete-title {
  font-size: 28px !important;
  margin: 0;
}

.register-content-wrapper {
  /* border-top: 1px solid #ededf4;
  margin-top: 10px;
  padding-top: 24px; */
  padding-bottom: 32px;
}

.register-content-wrapper.register {
  padding-bottom: 0px;
}

.register-content {
  display: flex;
  width: 100%;
}

/* .register-info {
  padding-right: 16px;
} */

.register-logo-container {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.register-logo {
  max-width: 90px;
  max-height: 52.5px;
  width: 100%;
  height: auto;
  margin-left: 20px;
}

.register-info-row:before {
  display: table;
  content: "";
}

.register-info-row {
  margin-bottom: 16px !important;
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.register-info-row:after {
  clear: both;
  display: table;
  content: "";
}

.register-info {
  width: 100%;
}

.register-info-column-left {
  line-height: 24px;
  box-sizing: border-box;
  float: left;
}

.register-label {
  display: inline-block;
  font-weight: bold;
  text-wrap: nowrap;
  width: 100px;
}

.register-info-column-right {
  word-break: break-word;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  box-sizing: border-box;
  float: left;
}
.register-info-column-right-reg {
  word-break: break-word;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px !important;
  width: 100%;
  box-sizing: border-box;
  float: left;
}

.register-btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.register-btn-container.top {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}
.activity-container {
  display: flex;
  flex-direction: column;
  min-height: 105vh;
  padding-bottom: 150px;
  /* margin: 0 5px; */
}

.share-container {
  background-color: #fff;
  border: 1px solid #e8ecf3;
  border-radius: 5px;
}
/* need to add flex-wrap:wrap to @media (max-width: 500px)
  also need to see why people-box is not aligning vertically when analytic tabs are aligned vertically
*/
.share-tabs {
  padding-left: 15px;
  display: flex;
  height: 60px;
  max-height: 60px;
  padding-top: 20px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid #e8ecf3;
}

.share-tab-button-container {
  height: 100%;
  display: flex;
  border: 2px solid transparent;
  padding: 0 13px;
  transition: 0.3s;
  user-select: none;
  background: transparent;
  color: #42454b;
}

.share-tab-button {
  cursor: pointer;
}

.share-tab-button-container.activate {
  border-bottom: 2px solid #1976d2;
  color: #1976d2;
}

.share-tab-button-container:hover,
.share-tab-button-container.activate.share-tab-button {
  color: #1976d2;
}

.views-tab-button-container {
  height: 100%;
  display: flex;
  border: 2px solid transparent;
  padding: 0 13px;
  transition: 0.3s;
  user-select: none;
  background: transparent;
  color: #42454b;
}

.views-tab-button {
  cursor: pointer;
}

.views-tab-button-container.activate {
  border-bottom: 2px solid #1976d2;
  color: #1976d2;
}

.views-tab-button-container:hover,
.views-tab-button-container.activate.views-tab-button {
  color: #1976d2;
}

.view-tabs {
  padding-left: 15px;
  display: flex;
  height: 60px;
  max-height: 60px;
  padding-top: 20px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid #e8ecf3;
  width: 100%;
}

.views-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e8ecf3;
}

.share-content {
  padding: 20px;
}

.share-link {
  display: flex;
  min-width: 100%;
  margin-bottom: 10px;
  align-items: flex-end;
  margin-left: 20px;
}

.share-link-url {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* margin-right: 20px; */
  color: #5d676b;
  padding: 0;
  margin: 0;
  margin-left: 5px;
  line-height: 1.2;
}
.getLeadsPriceSelector {
  top: 1px;
}

.getLeadsPriceSelector div {
  padding: 7px 22px 7px 22px !important;
  width: 78px;
}
.getLeadsInputs {
  margin-top: 18px;
}
.getLeadsStatusText {
  margin-top: 4px;
  margin-bottom: 18px;
}

.get-leads-bod {
  display: block;
  min-width: 100%;
  margin-bottom: 10px;
  align-items: flex-end;
  margin-left: 20px;
  margin-top: 6px;
}
.pplPromo {
  margin: 24px 42px;
  min-width: 60%;
}

.activity-data-container {
  border: 1px solid #e8ecf3;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background: #fff;
}
.activity-stats-container-outer {
  border-left: 1px solid #e8ecf3;
  border-right: 1px solid #e8ecf3;
}

.activity-line-graph-container {
  background-color: #fff;
  height: 400px;
  position: relative;
}

.no-data-overlay {
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  bottom: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.no-data-message {
  margin-top: -50px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  background-color: #fff;
}

.center-circular-progress {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
}

.copy-button {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: none;
  margin: 8px 0px 8px 0px;
  padding: 7px 12px;
  background: #1976d2;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.share-tab-header {
  color: #2c3336;
  font-size: 18px;
  font-weight: 600;
}

.share-info-tab {
  color: #6c737a;
  margin: 3px 0 9px;
  font-size: 14px;
}

.ssn-btns-container {
  display: flex;
  padding: 0px 180px 20px 0px;
  justify-content: flex-start;
}

.ssn-btn {
  display: flex;
  align-items: center;
  border: 1px solid #dee4e8;
  padding: 6px 12px;
  margin-right: 10px;
  border-radius: 4px;
}

.register-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.add-to-calendar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 40px 60px 40px;
}

.add-to-calendar-btn-container {
  display: flex;
  justify-content: space-between;
}

.add-to-calendar-title {
  align-self: center;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #42454b;
}

.add-to-calendar-btn {
  display: flex;
  align-items: center;
  border: 1px solid #42454b;
  padding: 8px 12px;
  border-radius: 50px;
  cursor: pointer;
  color: #42454b;
}

.add-to-calendar-text {
  font-size: 18px;
  margin-left: 4px;
}

.optional-msg-confirmation {
  padding-top: 15px;
}

.blue-border-effect:hover .cornerTop {
  position: absolute;
  top: 0px;
  left: -5px;
  width: 0px;
  height: 0px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #1976d2;
  transform-origin: left center;
  transform: translateY(-5px), scale(0);
  transition: transform 100ms ease-out;
}

.blue-border-effect:hover .cornerBottom {
  position: absolute;
  bottom: -5px;
  right: 0px;
  width: 0px;
  height: 0px;
  border-top: 5px solid #1976d2;
  border-right: 5px solid transparent;
  border-left: 5px solid #1976d2;
  transform-origin: center bottom;
  transform: translateX(5px), scale(0);
  transition: transform 100ms ease-out;
}

.blue-border-effect {
  position: relative;
  cursor: pointer;
}
.blue-border-effect:hover {
  position: relative;
  cursor: pointer;
  transform-origin: top right;
  transition: transform 100ms ease-out;
  transform: translate(5px, -5px);
  box-shadow: -5px 5px #1976d2;
}

.email-verify-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}

.email-verify-thank-you-title {
  font-size: 36px;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.email-verify-info-text {
  font-size: 18px;
  text-align: center;
  color: #666;
  margin-bottom: 30px;
}

.email-verify-next-steps {
  list-style-type: disc;
  margin-bottom: 40px;
  text-align: left;
  font-size: 16px;
  color: #555;
}

.email-verify-btn {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.circular-modal-style {
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.people-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #e8ecf3;
}
.message-people-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #e8ecf3;
}

.refresh-people-filter {
  display: flex;
  align-items: center;
}
.refresh-message-filter {
  display: flex;
  align-items: center;
}

.ripple-button-refresh-people {
  display: inline-flex; /* if you want the button to behave like inline but also use flex properties */
  align-items: center; /* vertically center the content */
  border-radius: 4px;
  border: none;
  padding: 5px;
  background: #1976d2;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: auto; /* allows the button to size according to its content */
  height: 36px;
  min-height: 20px; /* ensures that the button doesn't shrink below 20px */
  align-self: center;
}

.people-button-container {
  align-self: center;
  margin-right: 5px;
}

.btn-filter-refresh {
  display: inline-flex;
  flex-wrap: nowrap;
}

.message-button-container {
  align-self: center;
  margin-right: 5px;
}

.people-actions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.people-drop-down-container {
  min-width: 270px;
  background-color: #fff;
  border: 1px solid #e8ecf3;
  border-radius: 5px;
  border-top: 1.02px solid #e8ecf3;
  box-sizing: border-box;
  display: block;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
}

.people-drop-down-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.people-box {
  background: #fff;
  height: 72px;
  text-align: left;
  transition: all 0.3s ease;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 200px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.line-editing-btns-container {
  display: flex;
  align-items: flex-end;
}

.line-editing-check-btn {
  color: #1976d2;
  font-size: 16px;
  padding: 5px;
  border: 1px solid #5d676b;
  background: #eff3f6;
  border-radius: 4px;
  margin-right: 5px;
}

.line-editing-x-btn {
  color: red;
  font-size: 17px;
  padding: 5px;
  border: 1px solid #5d676b;
  background: #eff3f6;
  border-radius: 4px;
}

.person-name-email-avatar {
  display: flex;
}

.person-name-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.email-invite-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 20px;
}

.pagination-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.no-webinars-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
}

.activity-stats-wrapper {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.registered-attended-stat-block {
  padding: 10px 30px !important;
  font-size: 18px;
  font-weight: 400;
}

.registration-preview-statement {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #5d676b;
}

.copy-button-2 {
  background: none;
  border: none;
  color: #1976d2;
  margin: 0;
  padding: 0;
}

.email-copy-container {
  display: flex;
  justify-content: center;
}

.video-preview-container {
  min-width: 215px;
  max-width: 690px;
}

.video-preview-container {
  max-width: 550px;
  position: relative;
  height: 100%;
  align-self: center;
  /* padding-top: 56.25%; */
  /* overflow: hidden; */
}

.video-preview-container video {
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  /* width: 100%; */
  max-width: inherit;
  /* height: 100%; */
}

.video-form-wrapper {
  width: 95%;
  padding-right: 20px;
  padding-left: 20px;
  align-self: center;
}
.vjs-big-play-button {
  top: calc(50% - 45px) !important;
  left: calc(50% - 24px) !important;
}

.slide-preview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.faq-max-wrapper {
  max-width: 1200px;
}
.faqEntry {
  margin: 24px 36px;
}

.center-turnstile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.login-box {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: center;
}

input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}

.error {
  color: red;
}

#container {
  width: 100%;
  max-width: 960px;
}

.hls-player-topic {
  display: flex;
  font-size: 26px;
  align-self: flex-start;
  max-width: 500px;
}

.hls-title-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.hls-player-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.player-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.react-player {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.hls-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.player-loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background: grey;
  max-width: 1000px;
}

.player-loading-wheel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 13.75px;
  text-align: center;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
}

.video-js {
  width: 100%;
  height: 100%;
}

.hls-player {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
}

.video-placeholder {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  background-color: #ccc;
}

.aspect-ratio-box {
  width: 100%;
  position: relative;
}

.aspect-ratio-box::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}

.fit-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.play-icon-player {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 13.75px;
  text-align: center;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
}

.video-login-prompt {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 36px;
  text-align: center;
  padding: 0px 10px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
}

.aspect-ratio-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background-color: black;
}

.login-prompt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.login-prompt-text {
  color: white;
  font-size: 24px;
  /* position: absolute;
  top: 50%;
  left: 37.5%; */
}

.player-login-wrapper {
  width: 800px;
  height: 600px;
  background: black;
}

.player-login-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
}

.play-button-container {
  padding: 0 10px 0 20px;
  display: flex;
  align-items: center;
  min-width: 115px;
}

.analytic-icon-library {
  padding-left: 15px;
  cursor: pointer;
}

.error-boundary-inner {
  height: 100%;
  padding: 60px;
}

.error-boundary-wrap {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1976d2;
  color: #fff;
  padding: 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modal-body {
  padding: 20px;
  background: #fff;
  color: #5d676b;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #f2f2f2;
  padding: 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.close-button {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
}

.custom-button {
  background: #1976d2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.pre-post-container {
  font-family: Arial, sans-serif;
}

h1 {
  /* font-size: 20px; */
  margin-bottom: 10px;
}

.steps {
  margin-left: 20px;
}

.step-item {
  font-size: 20px;
  margin: 10px;
}

.unsubscribe-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  box-sizing: border-box;
}

.question-type-selector {
  margin-bottom: 20px;
}

.poll-min-max-wrapper {
  display: flex;
  flex-direction: column;
}

.poll-min-max-char {
  display: flex;
}

.poll-result-container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  align-items: center;
}

.poll-result-wrapper {
  max-height: 900px;
  overflow-y: auto;
}
.poll-result-inner-container {
  width: 75%;
  border: 1px solid #e8ecf3;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  padding-bottom: 20px;
}

.poll-question-container {
  border-bottom: 1px solid #edf6fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.poll-question {
  font-size: 20px;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.poll-answer-total {
  padding: 10px;
}

.poll-question-result {
  display: flex;
  flex-direction: column;
}

.poll-csv-download {
  padding-top: 8px;
  margin-left: 13.5%;
}

.poll-question-label {
  display: flex;
  align-items: center;
}

.short-answer-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.short-answer-item {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin-left: 20px;
  margin-right: 20px;
}

.short-answer-item:hover {
  background-color: #e9e9e9;
}

.short-answer-result {
  font-size: 16px;
  color: #333;
}

.poll-answer-total {
  margin-top: 10px;
  font-weight: bold;
}

.linear-result-bar {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0px 10px;
}

.linear-progress-container {
  flex-grow: 1;
}

.result-percentage {
  padding-left: 5px;
  margin: 0;
}

.option-result-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px 5px 10px;
}

.option-format-text {
  max-width: 544px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result-total-percent {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .unsubscribe-header,
  .unsubscribe-content {
    font-size: 16px;
  }

  .unsubscribe-container {
    padding: 10px;
  }

  .puppy-text-image {
    max-width: 100%;
  }
  .date-time-container.summary {
    margin-top: 20px;
  }
}

.unsubscribe-content {
  font-size: 20px;
}

.unsubscribe-list-items {
  list-style-type: disc;
  margin-top: 0px;
}

.unsubscribe-header {
  font-size: 24px;
  font-weight: bold;
}

.unsubscribe-item {
  padding: 1px 0px;
}

.unsubscribe-button-container {
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
}

.puppy-image {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.puppy-text-image {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  color: #325d7e;
  font-weight: bold;
}

.no-video-msg {
  display: flex;
  width: 100%;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
}

.email-google-text {
  background: #fff;
  padding: 10px;
  font-size: calc(5px + 1vw);
}

.email-google-text-second {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  flex-wrap: wrap;
}

.message {
  display: flex;
  margin-bottom: 10px;
}

.avatar {
  height: 40px;
  width: 40px;
  background-color: rgba(76, 175, 80, 1);
  color: white;
  border-radius: 50%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;
}

.text-content {
  border-radius: 10px;
  padding: 10px;
  flex-grow: 1;
}

.text-content-chat {
  border-radius: 10px;
  padding: 10px;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

.name {
  color: white;
  font-style: italic;
}

/* .content {
  margin-top: 5px;
} */

.chat-box {
  height: 700px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.chat-header {
  padding: 10px;
  background-color: #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  cursor: pointer;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.comparison-container {
  font-family: "Arial", sans-serif;
  text-align: center;
  margin: auto;
  padding: 20px;
  color: #333;
}

.features-table {
  width: 100%;
  /* border-collapse: collapse; */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.features-table th,
.features-table td {
  padding: 15px;
  text-align: center;
}

.features-table th {
  font-weight: bold;
  background-color: #ffffff;
}

.features-table th:first-child {
  border-top-left-radius: 8px;
}

.features-table th:last-child {
  border-top-right-radius: 8px;
}

.features-table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
  border: none;
}
.features-table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
  border: none;
}

.highlighted {
  background-color: rgba(237, 246, 251, 1);
  position: relative;
  z-index: 2;
  transform: scale(1.5);
}

.features-table th.highlighted,
.features-table td.highlighted:last-child {
  /* border-radius: 8px; */
  background-color: #ffffff;
}

.features-table th.highlighted {
  background-color: #edf6fb;
  color: #1976d2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 18px;
}

.features-table tr:last-child td.highlighted {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.3);
}

.features-table tr:last-child {
  border-bottom: none;
}

.features-table tbody tr {
  background-color: #ffffff;
  border-bottom: 1px solid #edf6fb;
}

.features-table th.highlighted,
.features-table td.highlighted:last-child {
  border-radius: 8px;
}

.features-table th:nth-child(3) {
  font-size: 21px;
}

.features-table th:nth-child(3),
.features-table td:nth-child(3) {
  padding-left: 90px;
  padding-right: 65px;
  border-bottom: 1px solid #edf6fb;
}

.features-table th:nth-child(1),
.features-table td:nth-child(1) {
  padding-left: 30px;
  padding-right: 60px;
  text-align: left;
  font-size: 24px;
  border-bottom: 1px solid #edf6fb;
}

.features-table.highlighted {
  position: relative;
}

.features-table.highlighted::after {
  content: "";
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
  box-shadow: 15px 0 15px -15px inset;
  pointer-events: none;
  z-index: 1;
}

.features-table th:nth-child(2),
.features-table td:nth-child(2) {
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}

.comparison-title {
  padding-bottom: 40px;
}

.additional-comparison-container {
  box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.5);
  margin-top: 10px;
  background: rgba(25, 118, 210, 0.5);
  width: 100%;
  color: rgba(0, 0, 0, 0.65);
}

.additional-comparison-content {
  margin: 0px 40px 15px 40px;
  /* font-size: 20px; */
}

.additional-comparison-info {
  margin-left: 40px;
}

.additional-comparison-content p {
  font-size: 20px;
  margin-left: 40px;
  margin-top: 5px;
}

td.highlighted::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 7px;
  left: 0;
  right: 0;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.3),
    5px 0 5px -5px rgba(0, 0, 0, 0.3);
  z-index: -1;
}
th.highlighted {
  box-shadow: 0 -3px 5px -5px rgba(0, 0, 0, 0.3);
  border-top: none;
}
th.highlighted::after {
  content: "";
  position: absolute;
  top: -1px;
  bottom: 7px;
  left: 0;
  right: 0;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.3),
    5px 0 5px -5px rgba(0, 0, 0, 0.3);
  z-index: -1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: none;
}

.features-table tr:last-child td.highlighted::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.3),
    5px 0 5px -5px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: -1;
}

.content-with-image .content-image {
  width: 96%;
  max-width: 300px;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.5);
  margin-top: 40px;
}

@media (max-width: 752px) {
  .comparison-container {
    padding: 10px;
    max-width: 100%;
  }

  .content-with-image .content-image {
    margin-top: 0px;
  }

  .content-with-image {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .content-with-image.reverse {
    flex-direction: column-reverse;
  }
  .features-table {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .features-table th,
  .features-table td {
    padding: 5px;
    font-size: smaller;
  }

  .features-table th:nth-child(1),
  .features-table td:nth-child(1) {
    padding-left: 10px;
    padding-right: 12px;
    font-size: 16px;
  }

  .features-table th.highlighted,
  .features-table td.highlighted {
    transform: scale(1.15);
  }

  td.highlighted::after {
    content: "";
    position: absolute;
    top: -4px;
    bottom: -2px;
    left: 0;
    right: 0;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.3),
      5px 0 5px -5px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }

  th.highlighted::after {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    right: 0;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.3),
      5px 0 5px -5px rgba(0, 0, 0, 0.3);
    z-index: -1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: none;
  }

  .features-table tr:last-child td.highlighted::after {
    content: "";
    position: absolute;
    top: -4px;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.3),
      5px 0 5px -5px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: -1;
  }
}

@media (max-width: 540px) {
  .comparison-container {
    padding: 5px;
  }

  .features-table th,
  .features-table td {
    padding: 3px;
    font-size: 12px;
  }

  .features-table {
    font-size: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .features-table-container {
    overflow-x: auto;
  }

  .features-table th:nth-child(3),
  .features-table td:nth-child(3) {
    padding-left: 20px;
    padding-right: 10px;
    font-size: 12px;
  }

  .features-table th:nth-child(2),
  .features-table td:nth-child(2) {
    padding-left: 20px;
    padding-right: 20px;
  }

  td.highlighted::after {
    content: "";
    position: absolute;
    top: -6px;
    bottom: -2px;
    left: 0;
    right: 0;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5),
      5px 0 5px -5px rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  th.highlighted::after {
    content: "";
    position: absolute;
    top: 0px;
    bottom: -1px;
    left: 0;
    right: 0;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5),
      5px 0 5px -5px rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .features-table tr:last-child td.highlighted::after {
    content: "";
    position: absolute;
    top: -6px;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5),
      5px 0 5px -5px rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: -1;
  }
}

.cancel-webinar-service-main-container {
  background: rgba(25, 118, 210, 0.5);
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.cancel-webinar-service-container.go-to-webinar {
  height: 90vh;
}

.cancel-webinar-service-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: #f2f2f2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.cancel-webinar-service-title {
  color: #454545;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
  align-self: center;
}

.cancel-webinar-service-content {
  font-size: 17px;
  line-height: 1.6;
  color: #666;
}

.cancel-webinar-service-steps {
  list-style-type: decimal;
  margin-left: 20px;
  margin-bottom: 15px;
}

.cancel-webinar-service-note,
.cancel-webinar-service-refund,
.cancel-webinar-service-freeze,
.cancel-webinar-service-help {
  margin-bottom: 15px;
}

.content-with-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-with-image .text-content {
  flex: 1;
  padding-right: 20px;
}

.content-with-image.reverse .text-content {
  padding-left: 20px;
  padding-right: 0;
}

.content-with-image.reverse {
  padding-bottom: 40px;
}

.cta-container {
  margin: 20px 0px;
}

.reset-password-request-error {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.reset-password {
  display: "flex";
  flex-direction: "column";
  align-items: "flex-end";
}

.password-reset-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.otd1a {
  padding-top: 40px;
}

.forgot-password-container {
  height: 20px;
}

.cta-stats-container {
  margin-top: 40px;
}

.speaker-sign-up-container {
  width: 100%;
  margin-top: 40px;
}

.speaker-item-container {
  margin: 5px 0;
  padding: 0;
  width: 100%;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #e8ecf3;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}

.speaker-name {
  width: 100%;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 380px;
}

.speaker-email {
  width: 100%;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speaker-item {
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  align-items: flex-start;
}

.speaker-name-delete-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.no-presenters-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.center-circular-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.user-info-image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  align-items: flex-start;
}

.outer-settings {
  margin: 10px 60px;
  padding: 5px 80px;
  width: 100%;
  max-width: 956px;
}

.user-email-container {
  position: relative;
  z-index: 0;
  width: 90%;
  max-width: 400px;
  min-width: 300px;
}

.setting-form-button-container {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.settings-field-label {
  align-self: flex-start;
}

.settings-header-section {
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
}

.settings-header-tabs {
  display: flex;
  align-items: flex-start;
  height: 40px;
  padding: 4px 10px 0px 0px;
  padding-top: 4px;
}

.settings-border {
  border-bottom: 1px solid #eff3f6;
  margin-bottom: 20px;
}

.host-presenter-info-fields {
  margin: 10px 0px;
  max-width: 509px;
}

.user-name-settings {
  margin-bottom: 10px;
}

.channel-profile-image-settings {
  margin-bottom: 20px;
}

.channel-image-info-container {
  display: flex;
}
.channel-image-info {
  padding-left: 20px;
}

.dropzone-image-container {
  min-width: 400px;
}

.outer-channel-name-info {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px;
  position: relative;
  z-index: 1;
  min-width: 0;
  max-width: 1200px;
  margin-left: 0px;
}
.channel-name-info {
  display: flex;
}

.channel-profile-image {
  display: flex;
  position: relative;
  margin-right: 24px;
  flex: 0 1 auto;
}

.channel-name {
  font-weight: 900;
  font-size: 36px;
  line-height: 1;
  /* margin-bottom: 10px; */
}

.channel-info-upcoming-webinar {
  width: 100%;
  max-width: 1200px;
  padding: 0 60px 0 60px;
}

.express-demo-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.content-margin {
  align-self: flex-start;
}
.name-description-category {
  /* width: 100%; */
  flex: 0.5 1 auto;
}

.description-container {
  display: flex;
  font-size: 18px;
  flex: 2 1 33%;
}

.name-category {
  display: flex;
  align-items: flex-start;
}

.description-expand {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 550px;
  padding-top: 10px;
}

.about-modal-container {
  background: #fff;
  padding: 20px;
  margin: auto;
  max-height: 746px;
  max-width: 746px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  min-width: 450px;
}

.about-modal-about {
  width: 100%;
  padding: 10px;
}

.about-modal-link {
  width: 100%;
  padding: 10px;
}

.about-titles {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
  /* padding-bottom: 20px; */
}

.quill {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
}

.ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
}

.ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
}

.incorrect-password-box {
  display: flex;
  align-items: center;
  background-color: #b22222;
  border-radius: 4px;
  color: white;
  padding: 12px 13px;
  margin-bottom: 15px !important;
  margin-top: 0px !important;
}

.or-sign-up-email {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 0 !important;
}

.or-sign-up-text {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.form-error-message {
  margin: 0px;
  padding: 0px;
  height: 0px;
  color: red;
}

.user-name-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.user-name-form.settings {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.first-last-name-settings {
  display: flex;
  width: 100%;
}

.watch-register {
  background-color: #1976d2 !important;
  border: none;
  color: white !important;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px #000;
}

.watch-register:hover {
  box-shadow: 0 6px 12px #000;
  transform: translateY(-2px);
}

.watch-webinar-description {
  font-size: 14px;
  line-height: 17px;
}

.more-info-data-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.waiting-room-container {
  display: flex;
  flex-direction: column;
}

.highlight {
  background-color: #f0f0f0;
  padding: 10px 5px;
  border-radius: 5px;
}

.centered-line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.centered-word {
  padding: 0 10px;
  font-size: 16px;
  white-space: nowrap;
  color: #1976d2;
  cursor: pointer;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: #000;
}

.nav-menu-catalog-container {
  width: 100%;
  max-width: 1000px;
}

.nav-menu-catalog {
  display: flex;
}

.nav-menu-catalog-tab {
  border-bottom: 1px solid #325d7e;
  padding: 0px 5px;
}

.nav-menu-catalog-tab.active {
  padding: 0px 5px;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
}
.rankingLabel{
  height: 22px;
  margin: auto;
  margin-left: 6px;
  margin-right: 6px;
  width: 40px;
  min-width: 40px;
  justify-content: left;
  display: inline-flex;

}

@media (max-width: 1600px) {
  .channel-info-upcoming-webinar {
    width: 100%;
    max-width: 1200px;
    padding: 0 60px 0 60px;
  }
}

@media (max-width: 542px) {
  .form-field-container {
    display: flex;
    margin: 0;
    min-width: 215px;
    margin-right: 5px;
  }
  .speaker-item-container {
    margin: 5px 0;
    padding: 0;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #e8ecf3;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    max-width: 480px;
  }
  .event-manage-header-top {
    flex-direction: column;
    margin-bottom: 20px;
  }
  /* .player-wrapper {
    padding-right: 10px;
    border-radius: 8px;
    overflow: hidden;
  } */
}

@media (max-width: 710px) {
  .webinars-search-total {
    color: #6c737a;
    margin-left: 0px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .speaker-item-container {
    margin: 5px 0;
    padding: 0;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #e8ecf3;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    max-width: 360px;
  }
  .speaker-name {
    width: 100%;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 310px;
  }
}

@media (max-width: 390px) {
  .speaker-item-container {
    margin: 5px 0;
    padding: 0;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #e8ecf3;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    max-width: 320px;
  }
  .speaker-name {
    width: 100%;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 270px;
  }
}

/* @media (min-width: 901px) {
  .speaker-item-container {
    margin: 5px 0;
    padding: 0;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #e8ecf3;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    max-width: 450px;
  }
  .speaker-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 450px;
  }
} */

@media (max-width: 768px) {
  .register-info {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .register-form {
    margin: 10px 0px 24px 0px;
    width: 720px;
    padding: 0 20px;
    background: #fff;
    width: 100%;
    border: none;
  }
  .register-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px !important;
  }
  .register-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .register-form-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .register-info-row {
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  .register-info-column-left {
    width: 100% !important;
    margin-bottom: 5px;
  }
  .register-info-column-right {
    width: 100% !important;
  }
  .register-info-column-right.date {
    white-space: nowrap;
  }
  .register-logo {
    margin-left: 0 !important;
  }
  .on-demand-topic {
    font-size: 24px;
    margin-top: 15px;
  }
  .content-wrap-register {
    width: 100%;
  }
  .add-to-calendar-btn-container {
    display: flex;
    flex-direction: column;
  }
  .add-to-calendar-btn {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
  .add-to-calendar-text {
    white-space: nowrap;
  }
  .register-success-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 10px;
  }
  .Box-content {
    padding: 5px;
    border-radius: 0 0 4px 4px;
    margin: auto;
    width: 100%;
  }

  .input-info-wrapper.category {
    margin-bottom: 20px !important;
  }
  .unsubscribe-header {
    font-size: 16px !important;
    font-weight: bold;
  }
  .unsubscribe-content {
    font-size: 16px !important;
  }
  .banner-image-container {
    text-align: center;
    padding-top: 5px;
  }
  .subcategory-option-container.channel-settings {
    margin-bottom: 20px;
  }
}

.webinar-icon-container.hidden {
  visibility: hidden;
}
.img-mobile{
  width: 100%;
  max-width: 150px;
  min-width: 150px;
  margin-right: 8px;
  height: 130px;
  object-fit: contain;
  border-radius: 8px;
  background: white;
  margin-left: 8px;

}
.ppl-content-box {
margin-left: 10px;
width:100%;
max-width: calc(100% - 168px);
overflow:none;

}
@media (max-width: 565px) {
  .img-media-container {
    max-width: 140px !important;
    width: 140px !important;
    margin-right: 5px !important;
  }
  .img-mobile {
    max-width: 140px !important;
    width: 100%;
    min-width:80px;
  }

  .ppl-content-box {
    margin-right: 0px;
    margin-left: 0px !important;
  }
}

@media (max-width: 500px) {
  #profile-image {
    display: none;
  }
  .channel-name-info {
    display: none;
  }
  /* .outer-channel-name-info {
    padding-left: 15px;
  } */
  .subcategory-option-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .channel-info-upcoming-webinar {
    width: 100%;
    max-width: 1200px;
    padding: 0px 10px;
  }
  .tab-panel-registration {
    min-width: 200px;
  }
  .activity-stats-wrapper {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .poll-result-inner-container {
    width: 100%;
    border: 1px solid #e8ecf3;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
  .poll-csv-download {
    padding-top: 8px;
    margin-left: 3%;
  }
  .outer-settings {
    margin: 10px 0px;
    padding: 5px 10px;
    width: 100%;
    max-width: 956px;
  }

  .tickHolder{
    display:none !important;
  }

}

@media (max-width: 600px) {
  .first-last-name-settings {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .or-sign-up-text {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .login-modal-title-text {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
  }
  .about-modal-container {
    padding: 15px;
  }
  .pre-settings-container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    border-bottom: 1px dashed #eff3f6;
    margin-bottom: 18px;
    padding-bottom: 20px;
  }
  .pre-meet-info {
    align-self: center;
    padding: 0 0 20px 0;
  }
  .pre-party-question-container {
    width: 100%;
    margin-left: 0px;
  }
  .Box {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #eff3f6;
    cursor: pointer;
    height: 110px;
    text-align: left;
    transition: all 0.3s ease;
  }
  .message-people-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
    border-bottom: 1px solid #e8ecf3;
  }
  .registered-attended-stat-block {
    padding: 10px 20px !important;
    font-size: 18px;
    font-weight: 400;
  }
  .register-complete-title {
    font-size: 20px !important;
  }
  .register-complete-title.mobile {
    margin-bottom: 0px;
  }
  .on-demand-topic {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media (max-width: 900px) {
  .dropzone-image-container {
    min-width: 400px;
    padding-left: 0;
  }
}

@media (max-width: 975px) {
  .people-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #e8ecf3;
  }
}

@media (max-width: 710px) {
  .refresh-people-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .subcategory-option-container {
    margin-bottom: 20px;
    margin-top: 0px;
    align-items: flex-start;
  }
  .category-subcategory-container {
    align-items: flex-start;
  }
  .input-info-wrapper.category.settings {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 920px) {
  .date-picker-container,
  .time-picker-container {
    padding-left: 0;
    padding-right: 0;
  }
  .form-field-container {
    margin: 0;
    min-width: 215px;
    margin-right: 5px;
  }
  .webinar-description-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /* margin-bottom: 20px; */
  }
  .category-subcategory-container {
    display: flex;
    align-items: flex-start !important;
  }
  .subcategory-option-container.channel-settings {
    margin-top: 20px;
  }
}

@media (min-width: 920px) {
  .form-field-container {
    margin-bottom: 0;
    min-width: 215px;
    margin-right: 5px;
  }
  .subcategory-option-container {
    margin-bottom: 0px;
    align-items: flex-end;
    margin-top: 0px;
  }
  .category-subcategory-container {
    align-items: flex-start;
  }
}

@media (max-width: 1000px) {
  .session-header-tabs {
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
  }
  .activity-container {
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (min-width: 768px) and (max-width: 920px) {
  .subcategory-option-container.enabled {
    margin-top: 20px;
  }
  /* .subcategory-option-container {
    margin-top: 20px;
  } */
}
@media (max-width: 450px) {
  .about-modal-container {
    min-width: 408px;
  }
}
@media (max-width: 415px) {
  .about-modal-container {
    min-width: 355px;
  }
}

@media (min-width: 1220px) {
  .outer-channel-name-info {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 16px 0px;
    position: relative;
    z-index: 1;
    min-width: 0;
    max-width: 1200px;
    margin-left: 0px;
  }
}
/* @media (min-width: 300px) {
  .email-google-text {
    font-size: 8px;
  }
}
@media (min-width: 1200px) {
  .email-google-text {
    font-size: 20px;
  }
} */
/* @media (min-width: 760px) {
  .email-google-text {
    font-size: 12px;
  }
} */
/* @media (min-width: 860px) {
  .email-google-text {
    font-size: 14px;
  }
}
/* @media (min-width: 1000px) {
  .email-google-text {
    font-size: 16px;
  }
} */

/* @media (min-width: 1200px) {
  .email-google-text {
    font-size: 20px;
  }
} */
